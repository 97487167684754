import { render, staticRenderFns } from "./ManualCriticismDialog.vue?vue&type=template&id=726b798f&scoped=true&style=background%3A%20%23E5E5E5&"
import script from "./ManualCriticismDialog.vue?vue&type=script&lang=js&"
export * from "./ManualCriticismDialog.vue?vue&type=script&lang=js&"
import style0 from "./ManualCriticismDialog.vue?vue&type=style&index=0&id=726b798f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "726b798f",
  null
  
)

export default component.exports